import {
    Checkbox,
    GridTableCell,
    GridTableRow,
    Input,
    Select as SelectE,
} from "@ramble/ramble-ui";
import { ClientDashboardModule, ClientModule, RoomModule } from "../../redux";
import React, {
    ChangeEvent,
    FC,
    memo,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import styled, { css } from "../../theme";

import HoverableItem from "./project_overview_table_hoverable_item";
import SummaryComponentHoverBox from "./summary_component_hover_box";
import notesImage from "../../assets/images/notes/notes_info.png";
import NotesHover from "./notes-modal/notes-modal";
import { FaEllipsisV } from "react-icons/fa";
import Pencil from "../../assets/icons/pencil.svg";
import TrashCan from "../../assets/icons/trash1.svg";
import Archive from "../../assets/icons/archive.svg";
import Duplicate from "../../assets/icons/duplicate.svg";
import DuplicateDisabled from "../../assets/icons/duplicate-disabled.svg";
import View from "../../assets/icons/view.svg";
import Collection from "../../assets/icons/collection.svg";
import moment from "moment";
import { DraperyModule } from "../../redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../reactDatePicker/ReactDatePicker.css";
import { SortTableRowInfo } from "../UI/table/sort_table";
// import { TiArrowSortedDown } from "react-icons/ti";

interface CellAlignProps {
    alignLeft?: boolean;
    noHover?: boolean;
}

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    cursor: default;
`;

const NotesInfoIcon: FC = () => {
    return (
        <IconWrapper>
            <img
                src={notesImage}
                alt="Notes Information"
                style={{ width: "100%", height: "100%" }}
            />
        </IconWrapper>
    );
};

export const rowCommonStyles = css`
    grid-template-rows: auto;
    column-gap: 0;
    padding: 0;
    position: unset;

    &:hover {
        background-color: inherit;
    }
`;

interface HeaderRowProps {
    scrollWidth: number;
    showClient?: boolean;
}

export const HeaderRow = styled(GridTableRow) <HeaderRowProps>`
    ${rowCommonStyles};
    background: #ecf6f9;
    color: #222222;
    font-weight: 500;
    border-bottom: 1px solid #2e2e2e2;
    font-weight: 500;
    height: 52px;
    &:hover {
        background-color: #ecf6f9;
    }

    ${(props) =>
        props.showClient
            ? css`
                  grid-template-columns: 1fr 3.5fr 3.5fr 3fr 1.5fr 1.5fr 2.5fr 2.5fr 3.5fr 2.5fr 2.5fr 1fr;
              `
            : css`
                  grid-template-columns: 1fr 3.5fr 3fr 1.5fr 1.5fr 3fr 2fr 4fr 3fr 3fr 1fr;
              `}
    ${(props) =>
        props.scrollWidth &&
        css`
            padding-right: ${props.scrollWidth}px;
        `}
`;

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    &:hover {
        background-color: #eee;
        border-radius: 50%;
    }
`;

const Dots = styled.div`
    padding: 5px;
`;

const PopupRow = styled.li`
    display: flex;
    aligne-items: center;
    list-style-type: none;
    &:hover {
        background-color: #eee;
    }
`;
const PopupRowList = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    width: 100%;
    height: 100%;
`;

const PopupRowListDisabled = styled.div`
    font-size: 16px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    width: 100%;
    height: 100%;
    opacity: 0.2;
`;

const AddButton = styled.div`
    position: absolute;
    // border-radius: 20px;
    box-shadow: 0px 10px 14px 8px #00000026, 0 6px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    top: 4px;
    right: 15px;
    animation: slidein 0.2s ease-in;
    width: 150px;
    border-radius: 12px;
    display: block;
    z-index: 999;
    ul {
        position: relative;
        z-index: 999999;
        width: 150px;
    }
    li {
        color: #6b6b6b;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        display: flex;
        padding: var(--10px, 10px);
        // border-top: 1px solid #eee;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        background-color: #fff;
        // height: 49px;
    }
    &.transition-enter,
    &.transition-appear {
        opacity: 1;
        transform: scaleY(0.1);
        transform-origin: 100% 0;
    }
    &.transition-enter-active,
    &.transition-appear-active {
        transform: scaleY(1);
        transition: transform 0.1s ease;
    }
    &.transition-exit {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: 100% 0;
    }
    &.transition-exit-active {
        opacity: 0.4;
        transform: scaleY(0.1);
        transition: transform 0.1s ease, opacity 0.1s ease;
    }
`;
interface RowProps {
    isChecked?: boolean;
    showClient?: boolean;
}

export const Row = styled(GridTableRow) <RowProps>`
    ${rowCommonStyles};
    border-bottom: solid 1px #e2e2e2;
    position: relative;
    // height: 52px;

    ${(props) =>
        props.showClient
            ? css`
                  grid-template-columns: 1fr 3.5fr 3.5fr 3fr 1.5fr 1.5fr 2.5fr 2.5fr 1.75fr 1.75fr 2.5fr 2.5fr 1fr;
              `
            : css`
                  grid-template-columns: 1fr 3.5fr 3fr 1.5fr 1.5fr 3fr 2fr 2fr 2fr 3fr 3fr 1fr;
              `}
    ${(props) =>
        props.isChecked &&
        css`
            background: #d6eaf0;
            &:hover {
                background-color: #d6eaf0;
            }
        `}
        &:hover {
        background-color: #eee;
    }
`;

export const miniRowCommonStyles = css`
    grid-template-rows: 1fr;
    background: transparent;
    height: 50%;
`;

export const MiniRowOne = styled(GridTableRow)`
    ${rowCommonStyles};
    ${miniRowCommonStyles};
    grid-template-columns: 1fr;
    padding-right: 1px;
    font-weight: 500;
    height: unset !important;
`;

export const MiniRowTwo = styled(GridTableRow)`
    ${rowCommonStyles};
    ${miniRowCommonStyles};
    grid-template-columns: 1fr 1fr;
    padding-right: 1px;
    font-weight: 500;
    height: unset !important;
`;

export const cellCommonStyles = css`
    border: none;
    white-space: normal;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;
    text-align: left;
    cursor: default;
    position: unset;

    &:first-child {
        border-left: none;
    }
`;
interface CellProps {
    className?: string;
}
export const HeaderCell = styled(GridTableCell) <CellProps>`
    ${cellCommonStyles};
    padding: 10px;
    text-align: center;
    font-size: 15px;
    align-items: flex-start;
    justify-content: ${(props) =>
        props.className && props.className.includes("leftAlign")
            ? "flex-start !important"
            : "center !important"};

    & .activeSort {
        color: ${({ theme }) => theme.colors.primary};
    }
    & .hoverableSort {
        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    & .sort_arrow {
        background: #d4d4d4;
        display: inline-flex;
        border: 1px solid #e2e2e2;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
        opacity: 1;

        &.hidden {
            opacity: 0;
        }

        & .arrow {
            font-size: 26px;
            color: ${({ theme }) => theme.colors.primary};

            &.rotate180 {
                transform: rotate(180deg);
            }
        }
    }

    &.sort_header_cell {
        cursor: pointer;

        &.editInlineField {
            &:hover {
                span {
                    color: ${({ theme }) => theme.colors.primary};
                }

                + .dropdownArrow {
                    color: ${({ theme }) => theme.colors.primary} !important;
                    fill: currentColor;
                }
            }
        }
        &.archivedColor {
            color: gray !important;
        }
        &:hover {
            & .invoice {
                color: ${({ theme }) => theme.colors.primary};
            }
        }

        &.activeSort .invoice {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const Cell = styled(GridTableCell) <CellAlignProps>`
    ${cellCommonStyles};
    font-size: 14px;
    padding: 6px !important;
    ${(props) =>
        props.alignLeft &&
        css`
            justify-content: flex-start;
        `}

    &.inlinePaid, &.inlineStatus {
        cursor: pointer;

        &.red {
            span {
                color: #ff6084;
            }
        }
        &.gray {
            span {
                color: gray;
            }
        }
        & .dropdownArrow {
            color: ${({ theme }) => theme.colors.primary} !important;
            fill: currentColor;
            font-size: 16px;
            display: inline-block;
            vertical-align: bottom;
            margin-left: 5px;
            // position: absolute;
            // top: 50%;
            // right: 1px;
            transform: translate(-1px, -50%);
            &.archivedColor {
                display: none;
            }
        }

        & .inlinePaidDropdown,
        & .inlineStatusDropdown {
            position: absolute;
            top: 100%;
            display: flex;
            flex-direction: column;
            z-index: 2000;
            background-color: #fff;
            box-shadow: 0px 10px 14px 8px #00000026,
                0 6px 5px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            color: #4a4a4a;
            font-size: 14px;
            overflow: hidden;
            width: 150px;

            & > li {
                cursor: pointer;
                padding: 10px 15px;
                &:hover {
                    background: #eee;
                }
            }
        }

        & .editInlineField {
            cursor: pointer;
            width: 100%;
            &:hover {
                color: ${({ theme }) => theme.colors.primary};
                cursor: pointer;

                .dropdownArrow {
                    color: ${({ theme }) => theme.colors.primary} !important;
                    fill: currentColor;
                }
            }
        }
        & .archivedColor {
            color: gray !important;
        }
    }

    input,
    select,
    option {
        color: rgba(27, 28, 29, 0.87) !important;
        font-weight: 100 !important;
    }
    &.linkCell {
        > div {
            height: 100%;
            > span {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &:hover {
            color: #51a6c5;
        }
    }
    &.archivedColor {
        > div {
            > span {
                color: gray !important;
            }
        }
    }
    ${(props) =>
        props.className === "menu" &&
        css`
            overflow: initial !important;
        `}
`;

export const SelectCell = styled(Cell)`
    cursor: pointer;
    // height: 52px;

    &:hover {
        color: #51a6c5 !important;
    }
    & .searchTerm{
        background: #e4e9f1;
    }
`;

export const ContainerCell = styled(GridTableCell)`
    ${cellCommonStyles};
`;

export const MiniCell = styled(GridTableCell)`
    ${cellCommonStyles};
    background: transparent;
    font-size: 15px;
    padding: 0px 4px;

    & .sort_arrow {
        background: #d4d4d4;
        display: inline-flex;
        border: 1px solid #e2e2e2;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
        opacity: 1;
        &.hidden {
            opacity: 0;
        }
        & .arrow {
            font-size: 26px;
            color: ${({ theme }) => theme.colors.primary};
            &.rotate180 {
                transform: rotate(180deg);
            }
        }
    }
    &.sort_header_cell {
        cursor: pointer;
        &.editInlineField {
            &:hover {
                span {
                    color: ${({ theme }) => theme.colors.primary};
                }
            }
        }
        &:hover {
            & .invoice {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
        &.activeSort .invoice {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

const InputText = styled(Input)`
    width: 100%;
    padding: 2px;
    font-size: 13px;
    background: transparent;
`;

const Select = styled(SelectE)`
    .select {
        border-radius: 5px;
        padding: 2px 30px 2px 2px;
        white-space: pre-line;
        color: red;
    }
`;
const CheckboxRow = styled(Checkbox)`
    .label {
        padding-left: 0;
    }
`;

const SpanPlaceholder = styled.span`
    color: #e4e4e4;
    font-weight: 400;

    &.hoverInline {
        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export interface ProjectOverviewTableRowProps {
    /**
     * If the current row is checked
     */
    isChecked?: boolean;
    /**
     * If the current row is being edited
     */
    isEditing?: boolean;
    /**
     * Project id
     */
    id: number;
    /**
     * Project definition
     */
    project: ClientDashboardModule.ClientProject;
    /**
     * Checked Projects
     */
    checkedProjects: number[];
    /**
     * All rooms
     */
    rooms: RoomModule.Room[];
    /**
     * Show client column
     */
    showClient?: boolean;
    /**
     * Row checked callback
     * @param id
     * @param checked
     */
    onCheckRow(id: number, checked: boolean): void;
    /**
     * Item column clicked
     * @param id
     * @param itemId
     */
    onItemClick(
        id: number | undefined,
        item?: number,
        target?: "summary",
        itemName?: string,
        customOrderType?: string | null
    ): void;
    /**
     * Workorder column clicked
     * @param id
     */
    onWorkOrderClick(id: number): void;
    /**
     * Project edited
     * @param id
     * @param newProject
     */
    handleNotCheckedBatchRow(
        id: number,
        project: ClientDashboardModule.ClientProject
    ): void;

    onEdited(newProject: ClientDashboardModule.ClientProject): void;
    notes?: string;
    handleActionMenu(arg: string): void;
    isPopoverOpen: boolean;
    setIsPopoverOpen(id: number | null): void;
    onOpenModal(value?: string): void;
    editProjectItem(
        project: ClientDashboardModule.ClientProject
    ): Promise<void>;
    rowIdEdit: number | undefined;
    rowKeyEdit: string;
    handleSetRowId(p: number | undefined, k: string): void | undefined;
    confirmEdit(): void;
    client?: ClientModule.Client;
    clients?: SortTableRowInfo[];
    searchTerm?: any;
}

export const ProjectOverviewTableRow: FC<ProjectOverviewTableRowProps> = memo(
    (props) => {
        const paidStatusRef = useRef<HTMLUListElement>(null);
        const statusRef = useRef<HTMLUListElement>(null);

        const handleClickOutside = (event: MouseEvent) => {
            if (
                paidStatusRef.current &&
                !paidStatusRef.current.contains(event.target as Node)
            ) {
                props.handleSetRowId(undefined, "");
            }
            if (
                statusRef.current &&
                !statusRef.current.contains(event.target as Node)
            ) {
                props.handleSetRowId(undefined, "");
            }
        };
        useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, []);

        const project = props.project;
        const client = props.client;

        // const clients = props.clients;
        const rowIdEdit = props.rowIdEdit;
        const rowKeyEdit = props.rowKeyEdit;

        const checkedProjects = props.checkedProjects;

        const getEatDate = (): boolean => {
            const currentDate = Date.now();
            let dateObject: Date | undefined;

            if (project.productionDuoDate) {
                dateObject = !project.productionDuoDate
                    ? undefined
                    : moment(project.productionDuoDate).isValid()
                        ? moment(project.productionDuoDate).toDate()
                        : undefined;
            }

            const timestamp = dateObject && dateObject.getTime();
            const bool =
                !project.productionFinishedDate &&
                timestamp &&
                timestamp < currentDate;
            return !!bool;
        };

        const handleOnClick = (field: string) => {
            props.handleSetRowId(project.itemId, field);
        };

        const handleOnBlur = () => {
            props.confirmEdit();
            props.handleSetRowId(undefined, "");
        };

        const sentDateToShow = !project.estimateDateSent
            ? null
            : moment(project.estimateDateSent).isValid()
                ? moment(project.estimateDateSent).format("MM/DD/YY")
                : null;

        const startDateToShow = !project.productionStartDate
            ? null
            : moment(project.productionStartDate).isValid()
                ? moment(project.productionStartDate).format("MM/DD/YY")
                : null;

        const dueDateToShow = !project.productionDuoDate
            ? null
            : moment(project.productionDuoDate).isValid()
                ? moment(project.productionDuoDate).format("MM/DD/YY")
                : null;

        const finishedDateToShow = !project.productionFinishedDate
            ? null
            : moment(project.productionFinishedDate).isValid()
                ? moment(project.productionFinishedDate).format("MM/DD/YY")
                : null;

        const installDateToShow = !project.installDate
            ? null
            : moment(project.installDate).isValid()
                ? moment(project.installDate).format("MM/DD/YY")
                : null;

        // const returnSubclient = () => {
        //     let subclient;
        //     if (client && client.subClients && client.subClients.length > 0) {
        //         for (let i = 0; i < client.subClients.length; i++) {
        //             if (project.clientName === client.displayName) {
        //             } else if (
        //                 client.subClients[i].parentClientId === client.id
        //             ) {
        //                 subclient = `(${client.displayName})`;
        //             }
        //         }
        //     } else if (client && client.parentClientId) {
        //         const foundParent =
        //             clients &&
        //             clients.length > 0 &&
        //             clients.find((c) => c.id === client.parentClientId);
        //         if (foundParent) {
        //             subclient = `(${foundParent.displayName})`;
        //         }
        //     }

        //     return subclient;
        // };

        const componentsPastEta = (): boolean => {
            const currentDate = Date.now();
            let isAnyComponentPastEta = false;

            if (project.components && project.components.length > 0) {
                project.components.forEach(
                    (component: DraperyModule.DraperyOrderSummaryComponent) => {
                        if (component.eta) {
                            const duoDate = moment(component.eta).isValid()
                                ? moment(component.eta).toDate()
                                : undefined;

                            if (duoDate) {
                                const timestamp = duoDate.getTime();
                                if (timestamp < currentDate) {
                                    isAnyComponentPastEta = true;
                                }
                            }
                        }
                    }
                );
            }

            return isAnyComponentPastEta;
        };
        const handleShowBatchOrEditOrder = useCallback((): boolean => {
            let checkCase: boolean = false;

            if (checkedProjects && checkedProjects.length >= 2) {
                checkCase = true;
            } else if (
                checkedProjects &&
                checkedProjects.length === 1 &&
                props.isPopoverOpen &&
                !checkedProjects.includes(props.id)
            ) {
                checkCase = true;
            } else {
                checkCase = false;
            }

            return checkCase;
        }, [checkedProjects, props.isPopoverOpen]);

        const showBatchOrEditOrder = handleShowBatchOrEditOrder();
        const editProjectValue = <
            K extends keyof ClientDashboardModule.ClientProject
        >(
            key: K,
            value: ClientDashboardModule.ClientProject[K]
        ): void => {
            const newProject = {
                ...project,
                [key]: value,
            };
            props.onEdited(newProject);
        };
        const checkRow = (e: ChangeEvent<HTMLInputElement>): void => {
            props.onCheckRow(props.id, e.currentTarget.checked);
        };

        const onItemClick = (): void => {
            if (project.itemType !== "Drapery Order" && project.itemType !== "Drapery" && !['BedSkirt', 'Cornice', 'Coverlet', 'Cushion', 'Drapery', 'Duvet', 'Pillow', 'Roman', 'TableSkirt', 'Upholstery', 'Valance', 'Other'].includes(project.itemType)) return;
            props.onItemClick(
                project.clientId,
                project.itemId,
                "summary",
                project.clientName,
                project.orderType,
            );
        };

        const onWorkOrderClick = (): void => {
            props.onWorkOrderClick(props.id);
        };

        const changeRoom = (e: ChangeEvent<HTMLSelectElement>): void => {
            const roomId = parseInt(e.currentTarget.value, 10);
            const room = props.rooms.find((r) => r.id === roomId);
            if (!room) {
                return;
            }
            const newProject = {
                ...project,
                roomName: room.name,
                roomId,
            };
            props.onEdited(newProject);
        };

        // const changeDetails = (e: ChangeEvent<HTMLInputElement>): void => {
        //     editProjectValue("details", e.currentTarget.value);
        // };

        const [isHovered, setIsHovered] = useState(false);

        const showNotes = () => {
            setIsHovered(true);
        };

        const hideNotes = () => {
            setIsHovered(false);
        };

        const changeStatus = (
            v: ClientDashboardModule.ClientProject["status"]
        ): void => {
            editProjectValue("status", v);
        };

        const handleChangeStatus = (
            v: ClientDashboardModule.ClientProject["status"]
        ) => {
            changeStatus(v);
            setTimeout(() => {
                handleOnBlur();
                props.handleSetRowId(undefined, "");
            }, 10);
        };

        const changeEstimate = (e: ChangeEvent<HTMLInputElement>): void => {
            editProjectValue("estimateNumber", e.currentTarget.value);
        };

        const changeInvoiceNumber = (
            e: ChangeEvent<HTMLInputElement>
        ): void => {
            editProjectValue("invoiceNumber", e.currentTarget.value);
        };

        const changeInvoicePaid = (v: string): void => {
            editProjectValue("invoicePaid", v);
        };

        const handleChangePaidStatus = (v: string) => {
            changeInvoicePaid(v);
            setTimeout(() => {
                handleOnBlur();
                props.handleSetRowId(undefined, "");
            }, 10);
        };

        // const changeComponentsOrdered = (
        //     e: ChangeEvent<HTMLInputElement>
        // ): void => {
        //     editProjectValue("componentsOrdered", e.currentTarget.value);
        // };

        // const changeComponentsReceived = (
        //     e: ChangeEvent<HTMLInputElement>
        // ): void => {
        //     editProjectValue("componentsReceived", e.currentTarget.value);
        // };

        const highlightText = (text: any, searchTerm: any, delay?: number) => {
            if (!searchTerm) return text
            if (!searchTerm.trim()) return text;

            const escapedSearchTerm = searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

            const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
            const parts = text.split(regex);

            return parts.map((part: any, index: any) =>
                regex.test(part) ? (
                    <span key={index} className="searchTerm">
                        {part}
                    </span>
                ) : (
                    part
                )
            );
        };

        const changeDates = (v: Date | null, field: any): void => {
            if (!v) {
                editProjectValue(field, null);
            } else {
                const date = new Date(v);
                editProjectValue(field, date);
            }
        };

        const changeInstallNumber = (
            e: ChangeEvent<HTMLInputElement>
        ): void => {
            editProjectValue("installNumber", e.currentTarget.value);
        };

        const componentOrderDate =
            project.components &&
            project.components.length > 0 &&
            project.components.every(
                (component: any) =>
                    component.orderedDate && component.orderedDate !== ""
            );
        const componentRecievedData =
            project.components &&
            project.components.length > 0 &&
            project.components.every(
                (component: any) =>
                    component.receivedDate && component.receivedDate !== ""
            );

        return (
            <Row
                style={project.archived ? { backgroundColor: "#f7f7f7" } : {}}
                className="grid-table-row"
                isChecked={props.isChecked || false}
                showClient={props.showClient}
            >
                <Cell>
                    <CheckboxRow
                        checked={props.isChecked || false}
                        onChange={checkRow}
                    />
                </Cell>
                {props.showClient && (
                    <Cell>
                        <HoverableItem
                            className="leftAlign"
                            archived={project.archived}
                        >
                            <span>{highlightText(project.clientName, props.searchTerm)}</span> <br />
                            {(client && client.subClients && client.subClients.length > 0 && (client.subClients.some((i: any) => i.id === project.clientId) || client.id === project.clientId)) ? null : project.mainParent ? <span style={{ color: 'gray', fontSize: '12px' }}>({highlightText(project.mainParent.displayName, props.searchTerm)})</span> : null}
                        </HoverableItem>
                    </Cell>
                )}

                <Cell>
                    {props.isEditing &&
                        !project.archived &&
                        !project.workOrderNum ? (
                        <Select value={project.roomId} onChange={changeRoom}>
                            {props.rooms.map((room, id) => (
                                <option key={id} value={room.id}>
                                    {room.name}
                                </option>
                            ))}
                        </Select>
                    ) : (
                        <HoverableItem className={`${project.itemType === 'Drapery Order' || project.itemType === 'Drapery' || project.itemType ? 'editInlineField' : ''} leftAlign`} archived={project.archived}>
                            <span style={{ cursor: 'pointer' }} onClick={onItemClick}>
                                {project.itemType ? highlightText(project.itemType === "Drapery Order" || project.itemType === "Drapery"
                                    ? `Drapery ${project.window ? `/ ${project.window}` : ''} `
                                    : `${project.itemType} ${project.locationId ? `/ ${project.locationId}` : ''}`, props.searchTerm) : ''}
                            </span>
                            <br />
                            <span style={{ color: 'gray', fontSize: '12px' }}>{project.roomName ? highlightText(project.roomName, props.searchTerm) : highlightText((project.customRoom || ""), props.searchTerm)}</span>
                        </HoverableItem>
                    )}
                </Cell>
                <Cell
                    style={{ minWidth: "130px" }}
                    className={`inlineStatus ${project.archived === false
                        ? project.status === "New"
                            ? "red"
                            : ""
                        : "gray"
                        }`}
                >
                    <HoverableItem
                        className={`${!project.archived
                            ? "editInlineField"
                            : "archivedColor"
                            } leftAlign`}
                        handleSetRowId={() => handleOnClick("status")}
                    >
                        <span>{highlightText(project.status, props.searchTerm)}</span>
                        {project.archived && (
                            <div
                                style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                    textAlign: "left",
                                }}
                            >
                                {"(archived)"}
                            </div>
                        )}
                        {/* <TiArrowSortedDown
                            className={`dropdownArrow ${
                                project.archived ? "archivedColor" : ""
                            }`}
                        /> */}
                        <svg
                            className={`dropdownArrow ${project.archived ? "archivedColor" : ""
                                }`}
                            focusable={false}
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 40 40"
                            width="12px"
                            height="12px"
                        >
                            <g>
                                <path d="m37.6 18l-16.6 16.6q-0.4 0.4-1 0.4t-1-0.4l-16.6-16.6q-0.4-0.4-0.4-1t0.4-1l3.7-3.7q0.5-0.4 1-0.4t1 0.4l11.9 11.9 11.9-11.9q0.4-0.4 1-0.4t1 0.4l3.7 3.7q0.4 0.4 0.4 1t-0.4 1z" />
                            </g>
                        </svg>
                    </HoverableItem>

                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "status" &&
                        !project.archived && (
                            <ul
                                ref={statusRef}
                                className="inlineStatusDropdown"
                            >
                                <li onClick={() => handleChangeStatus("New")}>
                                    New
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Planning")
                                    }
                                >
                                    Planning
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Measured")
                                    }
                                >
                                    Measured
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Need Measure")
                                    }
                                >
                                    Need Measure
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Pending")
                                    }
                                >
                                    Pending
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Approved")
                                    }
                                >
                                    Approved
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Production")
                                    }
                                >
                                    Production
                                </li>
                                <li onClick={() => handleChangeStatus("Ready")}>
                                    Ready
                                </li>
                                <li
                                    onClick={() => handleChangeStatus("Closed")}
                                >
                                    Closed
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangeStatus("Rejected")
                                    }
                                >
                                    Rejected
                                </li>
                            </ul>
                        )}
                    {/* )} */}
                </Cell>
                <Cell onMouseEnter={showNotes} onMouseLeave={hideNotes}>
                    {project.notes ? (
                        <>
                            <NotesInfoIcon />
                            {isHovered && <NotesHover notes={project.notes} />}
                        </>
                    ) : (
                        <></>
                    )}
                </Cell>
                {project.workOrderNum ? (
                    <SelectCell
                        noHover={project.archived}
                        style={{
                            textAlign: "center",
                            color:
                                project.archived === false
                                    ? project.isDraft
                                        ? "#e74f77"
                                        : "initial"
                                    : "#6B6B6B",
                        }}
                        onClick={onWorkOrderClick}
                    >
                        {highlightText(project.workOrderNum.toString(), props.searchTerm)}
                    </SelectCell>
                ) : (
                    <Cell />
                )}
                <Cell style={{ display: "flex", flexDirection: "column" }}>
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "estimateNumber" &&
                        !project.archived ? (
                        <InputText
                            type="text"
                            value={project.estimateNumber || ""}
                            onChange={changeEstimate}
                            onBlur={handleOnBlur}
                            autoFocus
                        />
                    ) : (
                        <HoverableItem
                            className={`${!project.archived
                                ? "editInlineField"
                                : "archivedColor"
                                } paddingLeft leftAlign`}
                            handleSetRowId={() =>
                                handleOnClick("estimateNumber")
                            }
                        >
                            {project.estimateNumber ? highlightText(project.estimateNumber.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>#</SpanPlaceholder>}
                        </HoverableItem>
                    )}
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "estimateDateSent" &&
                        !project.archived ? (
                        <div
                            style={{ width: "100%" }}
                            className="inlinePosition"
                        >
                            <DatePicker
                                className="custom_date_picker"
                                selected={
                                    !project.estimateDateSent
                                        ? null
                                        : moment(
                                            project.estimateDateSent
                                        ).isValid()
                                            ? moment(
                                                project.estimateDateSent
                                            ).toDate()
                                            : null
                                }
                                onChange={(v: Date | null) =>
                                    changeDates(v, "estimateDateSent")
                                }
                                placeholderText="MM/DD/YY"
                                autoFocus
                                onBlur={handleOnBlur}
                            />
                        </div>
                    ) : (
                        <HoverableItem
                            className={`${!project.archived
                                ? "editInlineField"
                                : "archivedColor"
                                } leftAlign`}
                            handleSetRowId={() =>
                                handleOnClick("estimateDateSent")
                            }
                        >
                            {sentDateToShow ? highlightText(sentDateToShow.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>MM/DD/YY</SpanPlaceholder>}
                        </HoverableItem>
                    )}
                </Cell>
                <Cell
                    className="inlinePaid"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "82px",
                    }}
                >
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "invoiceNumber" &&
                        !project.archived ? (
                        <InputText
                            type="text"
                            value={project.invoiceNumber || ""}
                            onChange={changeInvoiceNumber}
                            onBlur={handleOnBlur}
                            autoFocus
                        />
                    ) : (
                        <HoverableItem
                            className={`${!project.archived
                                ? "editInlineField"
                                : "archivedColor"
                                } paddingLeft leftAlign`}
                            handleSetRowId={() =>
                                handleOnClick("invoiceNumber")
                            }
                        >
                            {project.invoiceNumber ? highlightText(project.invoiceNumber.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>#</SpanPlaceholder>}
                        </HoverableItem>
                    )}
                    <HoverableItem
                        handleSetRowId={() => handleOnClick("invoicePaid")}
                        className={`${project.invoicePaid === "No" ? "danger" : ""
                            } editInlineField paddings leftAlign ${project.archived ? "archivedColor" : ""
                            }`}
                    >
                        {project.invoicePaid ? (
                            <>
                                <span style={{ fontSize: "12px" }}>
                                    {project.invoicePaid === 'Yes' ? highlightText('Paid', props.searchTerm) : project.invoicePaid === 'No' ? highlightText('Not paid', props.searchTerm) : highlightText('Partial', props.searchTerm)}
                                </span>
                                <svg
                                    className={`inlinePaidArrow ${project.archived ? "archivedArrow" : ""
                                        } ${!project.invoicePaid
                                            ? "arrow_without_value"
                                            : ""
                                        }`}
                                    focusable={false}
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 40 40"
                                    width="12px"
                                    height="12px"
                                >
                                    <g>
                                        <path d="m37.6 18l-16.6 16.6q-0.4 0.4-1 0.4t-1-0.4l-16.6-16.6q-0.4-0.4-0.4-1t0.4-1l3.7-3.7q0.5-0.4 1-0.4t1 0.4l11.9 11.9 11.9-11.9q0.4-0.4 1-0.4t1 0.4l3.7 3.7q0.4 0.4 0.4 1t-0.4 1z" />
                                    </g>
                                </svg>
                            </>
                        ) : (
                            <>
                                <SpanPlaceholder
                                    className={
                                        !project.archived ? "hoverInline" : ""
                                    }
                                >
                                    select
                                </SpanPlaceholder>
                                <svg
                                    className={`inlinePaidArrow ${project.archived ? "archivedArrow" : ""
                                        } ${!project.invoicePaid
                                            ? "placeholderArrow"
                                            : ""
                                        }`}
                                    focusable={false}
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 40 40"
                                    width="12px"
                                    height="12px"
                                >
                                    <g>
                                        <path d="m37.6 18l-16.6 16.6q-0.4 0.4-1 0.4t-1-0.4l-16.6-16.6q-0.4-0.4-0.4-1t0.4-1l3.7-3.7q0.5-0.4 1-0.4t1 0.4l11.9 11.9 11.9-11.9q0.4-0.4 1-0.4t1 0.4l3.7 3.7q0.4 0.4 0.4 1t-0.4 1z" />
                                    </g>
                                </svg>
                            </>
                        )}
                        {/* <TiArrowSortedDown className={`inlinePaidArrow ${project.archived ? 'archivedArrow' : ''} ${!project.invoicePaid ? 'arrow_without_value' : ''}`} /> */}
                    </HoverableItem>
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "invoicePaid" &&
                        !project.archived && (
                            <ul
                                ref={paidStatusRef}
                                className="inlinePaidDropdown"
                            >
                                <li
                                    onClick={() => handleChangePaidStatus("No")}
                                >
                                    Not paid
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangePaidStatus("Yes")
                                    }
                                >
                                    Paid
                                </li>
                                <li
                                    onClick={() =>
                                        handleChangePaidStatus("Partial")
                                    }
                                >
                                    Partial
                                </li>
                            </ul>
                        )}
                </Cell>
                <Cell
                    className={`linkCell ${project.archived && "archivedColor"
                        }`}
                >
                    <SummaryComponentHoverBox
                        displayText={componentOrderDate ? "Yes" : "No"}
                        components={project.components}
                    >
                        {componentOrderDate ? highlightText('Yes', props.searchTerm) : highlightText('No', props.searchTerm)}
                    </SummaryComponentHoverBox>
                </Cell>
                <Cell
                    className={`linkCell ${project.archived && "archivedColor"
                        }`}
                >
                    <SummaryComponentHoverBox
                        displayText={componentRecievedData ? "Yes" : "No"}
                        components={project.components}
                        componentsPastEta={componentsPastEta}
                    >
                        {componentRecievedData ? highlightText('Yes', props.searchTerm) : highlightText('No', props.searchTerm)}
                    </SummaryComponentHoverBox>
                </Cell>
                <Cell style={{ display: "flex", flexDirection: "column" }}>
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "productionStartDate" &&
                        !project.archived ? (
                        <div
                            style={{ width: "100%" }}
                            className="inlinePosition"
                        >
                            <DatePicker
                                className="custom_date_picker"
                                selected={
                                    !project.productionStartDate
                                        ? null
                                        : moment(
                                            project.productionStartDate
                                        ).isValid()
                                            ? moment(
                                                project.productionStartDate
                                            ).toDate()
                                            : null
                                }
                                onChange={(v: Date | null) =>
                                    changeDates(v, "productionStartDate")
                                }
                                placeholderText="MM/DD/YY"
                                autoFocus
                                onBlur={handleOnBlur}
                            />
                        </div>
                    ) : (
                        <HoverableItem
                            className={`${!project.archived
                                ? "editInlineField"
                                : "archivedColor"
                                } leftAlign`}
                            handleSetRowId={() =>
                                handleOnClick("productionStartDate")
                            }
                        >
                            {startDateToShow ? highlightText(startDateToShow.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>MM/DD/YY</SpanPlaceholder>}
                        </HoverableItem>
                    )}
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "productionFinishedDate" &&
                        !project.archived ? (
                        <div
                            style={{ width: "100%" }}
                            className="inlinePosition"
                        >
                            <DatePicker
                                className="custom_date_picker"
                                selected={
                                    !project.productionFinishedDate
                                        ? null
                                        : moment(
                                            project.productionFinishedDate
                                        ).isValid()
                                            ? moment(
                                                project.productionFinishedDate
                                            ).toDate()
                                            : null
                                }
                                onChange={(v: Date | null) =>
                                    changeDates(v, "productionFinishedDate")
                                }
                                placeholderText="MM/DD/YY"
                                autoFocus
                                onBlur={handleOnBlur}
                            />
                        </div>
                    ) : (
                        <HoverableItem
                            className={`leftAlign ${getEatDate() ? "danger" : ""
                                } ${!project.archived
                                    ? "editInlineField"
                                    : "archivedColor"
                                }`}
                            handleSetRowId={() =>
                                handleOnClick("productionFinishedDate")
                            }
                        >
                            {project.productionDuoDate &&
                                !project.productionFinishedDate && (
                                    <span
                                        className={
                                            !project.archived
                                                ? "eta"
                                                : "archivedColor"
                                        }
                                    >
                                        ETA{" "}
                                    </span>
                                )}
                            {project.productionFinishedDate ? (finishedDateToShow ? highlightText(finishedDateToShow.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>MM/DD/YY</SpanPlaceholder>) : (
                                dueDateToShow && <span className={getEatDate() ? 'danger' : 'gray'}>{highlightText(dueDateToShow.toString(), props.searchTerm)}</span> ||
                                <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>MM/DD/YY</SpanPlaceholder>
                            )}
                        </HoverableItem>
                    )}
                </Cell>
                <Cell style={{ display: "flex", flexDirection: "column" }}>
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "installNumber" &&
                        !project.archived ? (
                        <InputText
                            type="text"
                            value={project.installNumber || ""}
                            onChange={changeInstallNumber}
                            onBlur={handleOnBlur}
                            autoFocus
                        />
                    ) : (
                        <HoverableItem
                            className={`${!project.archived
                                ? "editInlineField"
                                : "archivedColor"
                                } paddingLeft leftAlign`}
                            handleSetRowId={() =>
                                handleOnClick("installNumber")
                            }
                        >
                            {project.installNumber ? highlightText(project.installNumber.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>#</SpanPlaceholder>}
                        </HoverableItem>
                    )}
                    {rowIdEdit === project.itemId &&
                        rowKeyEdit === "installDate" &&
                        !project.archived ? (
                        <div
                            style={{ width: "100%" }}
                            className="inlinePosition"
                        >
                            <DatePicker
                                className="custom_date_picker"
                                selected={
                                    !project.installDate
                                        ? null
                                        : moment(project.installDate).isValid()
                                            ? moment(project.installDate).toDate()
                                            : null
                                }
                                onChange={(v: Date | null) =>
                                    changeDates(v, "installDate")
                                }
                                placeholderText="MM/DD/YY"
                                autoFocus
                                onBlur={handleOnBlur}
                            />
                        </div>
                    ) : (
                        <HoverableItem
                            className={`${!project.archived
                                ? "editInlineField"
                                : "archivedColor"
                                } leftAlign`}
                            handleSetRowId={() => handleOnClick("installDate")}
                        >
                            {installDateToShow ? highlightText(installDateToShow.toString(), props.searchTerm) : <SpanPlaceholder className={!project.archived ? 'hoverInline' : ''}>MM/DD/YY</SpanPlaceholder>}
                        </HoverableItem>
                    )}
                </Cell>
                <Cell className="menu">
                    <Container>
                        <Dots
                            className="cursor-pointer"
                            onClick={() => props.setIsPopoverOpen(props.id)}
                        // onMouseLeave={() => }
                        >
                            <FaEllipsisV />
                        </Dots>
                        {props.isPopoverOpen && (
                            <AddButton>
                                <>
                                    <ul>
                                        {showBatchOrEditOrder ? (
                                            <PopupRow>
                                                <PopupRowList
                                                    onClick={(e) => {
                                                        props.handleActionMenu(
                                                            "Batch Edit"
                                                        );
                                                        props.setIsPopoverOpen(
                                                            -1
                                                        );
                                                        props.handleNotCheckedBatchRow(
                                                            props.id,
                                                            project
                                                        );
                                                    }}
                                                >
                                                    <Collection
                                                        style={{
                                                            marginRight: "5px",
                                                            height: "16px",
                                                            marginLeft: "-3px",
                                                        }}
                                                    />
                                                    <span>Batch Edit</span>
                                                </PopupRowList>
                                            </PopupRow>
                                        ) : (
                                            <PopupRow>
                                               {project.itemType !== 'Drapery Order' && project.itemType !== 'Drapery' && !['BedSkirt', 'Cornice', 'Coverlet', 'Cushion', 'Drapery', 'Duvet', 'Pillow', 'Roman', 'TableSkirt', 'Upholstery', 'Valance', 'Other'].includes(project.itemType) ? (
                                                    <PopupRowListDisabled>
                                                        <Pencil
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                        />
                                                        <span>Edit Order</span>
                                                    </PopupRowListDisabled>
                                                ) : (
                                                    <PopupRowList
                                                        onClick={(e) => {
                                                            onItemClick();
                                                        }}
                                                    >
                                                        {project.archived ===
                                                            false ? (
                                                            <Pencil
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <View
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                }}
                                                            />
                                                        )}
                                                        <span>
                                                            {project.archived
                                                                ? "View"
                                                                : "Edit Order"}
                                                        </span>
                                                    </PopupRowList>
                                                )}
                                            </PopupRow>
                                        )}
                                        <PopupRow
                                            aria-disabled={project.archived}
                                            style={
                                                project.archived
                                                    ? {
                                                        color: "#D4D4D4",
                                                        fontStyle: "italic",
                                                    }
                                                    : {}
                                            }
                                        >
                                            <PopupRowList
                                                onClick={() => {
                                                    if (
                                                        project.archived ===
                                                        false
                                                    ) {
                                                        props.onCheckRow(
                                                            props.id,
                                                            true
                                                        );
                                                        props.onOpenModal(
                                                            "Duplicate"
                                                        );
                                                        props.setIsPopoverOpen(
                                                            -1
                                                        );
                                                    }
                                                }}
                                            >
                                                {project.archived === false ? (
                                                    <>
                                                        <Duplicate
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                        />
                                                        <span>Duplicate</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <DuplicateDisabled
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                        />
                                                        <span>Duplicate</span>
                                                    </>
                                                )}
                                            </PopupRowList>
                                        </PopupRow>
                                        <PopupRow>
                                            <PopupRowList
                                                style={{}}
                                                onClick={async () => {
                                                    await Promise.resolve(
                                                        props.onCheckRow(
                                                            props.id,
                                                            true
                                                        )
                                                    );
                                                    if (
                                                        project.archived ===
                                                        false
                                                    ) {
                                                        props.onOpenModal(
                                                            "Archive"
                                                        );
                                                    } else {
                                                        props.handleActionMenu(
                                                            "Unarchive"
                                                        );
                                                    }
                                                    props.setIsPopoverOpen(-1);
                                                }}
                                            >
                                                <Archive
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                <span>
                                                    {project.archived
                                                        ? "Unarchive"
                                                        : "Archive"}
                                                </span>
                                            </PopupRowList>
                                        </PopupRow>
                                        <PopupRow>
                                            <PopupRowList
                                                onClick={() => {
                                                    props.onCheckRow(
                                                        props.id,
                                                        true
                                                    );
                                                    props.onOpenModal("Delete");
                                                    props.setIsPopoverOpen(-1);
                                                }}
                                            >
                                                <TrashCan
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                <span>Delete</span>
                                            </PopupRowList>
                                        </PopupRow>
                                    </ul>
                                </>
                            </AddButton>
                        )}
                    </Container>
                </Cell>
            </Row>
        );
    }
);
